.wishlist-modal {
  display: none;
  opacity: 0;
  pointer-events: none;
  z-index: 0;

  &.show {
    display: block;
    opacity: 1;
    pointer-events: all;
    z-index: 1051;

    + .modal-backdrop {
      pointer-events: all;
    }
  }

  &.fade .modal-dialog {
    max-width: 34.375rem;
    transform: translateY(0);
  }

  .close {
    font-weight: 400;
    color: #7a7a7a;
    opacity: 1;
    font-size: 2.25rem;

    &:hover {
      opacity: 0.6;
    }
  }

  .modal {
    &-header {
      padding: 0.625rem 1.875rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;

      h5 {
        color: #232323;
        font-size: 1.375rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1.875rem;
      }

      &::after {
        content: none;
      }
    }

    &-text {
      color: #232323;
      font-size: 0.875rem;
      letter-spacing: 0;
      line-height: 1.875rem;
    }

    &-body {
      padding: 0.9375 1.875rem;

      .form-group {
        margin-bottom: 0;

        .form-control {
          border-radius: 0;
          background: none;
          color: black;
        }
      }
    }

    &-content {
      width: 100%;
    }

    &-cancel {
      margin-right: 0.625rem;

      &:hover {
        opacity: 0.7;
      }
    }

    &-footer {
      padding: 0.75rem 1.875rem;
      border: none;
      padding-bottom: 1.875rem;

      .btn {
        text-transform: none;
      }
    }

    &-backdrop {
      pointer-events: none;

      &.in {
        pointer-events: all;
      }
    }
  }

  + .modal-backdrop {
    pointer-events: none;

    &.in {
      pointer-events: all;
    }
  }
}
