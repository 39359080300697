.wishlist {
  &-footer {
    &-links {
      margin-bottom: 3.125rem;

      > a {
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.1875rem;

        &:not(:first-child) {
          margin-left: 1.25rem;
        }

        i {
          font-size: 1.25rem;
          margin-right: 0.25rem;
          vertical-align: middle;
        }
      }
    }
  }
}
